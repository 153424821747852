<template>
  <section class="cont cash" @click="CustomVisible = false">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <div class="box">
        <div style="display:flex" class="note-box">
          <div><span style="font-size:20px;font-weight:bold;margin-right:20px">剩余短信余额</span>{{smsBalance}}条</div>
        </div>
        <!-- <el-button type="primary" @click="dialogsignVisible = true">签名配置</el-button> -->
       <el-button type="primary" @click.stop="getCustomerConfig">客服充值</el-button>
      </div>
    </el-row>

    <el-row class="content-box m-top-20">
      <el-row class="table-box">
        <el-tabs value="use">
          <el-tab-pane label="活动明细" name="use">
            <el-table :data="useData" v-loading="tableLoad" border fit :stripe="true">
              <el-table-column type="index" label="序号" width="100"></el-table-column>
              <el-table-column prop="eventName" label="活动名称"></el-table-column>
              <el-table-column prop="userCount" label="人群数量"></el-table-column>
              <el-table-column prop="usedQuantity" label="使用条数"></el-table-column>
              <el-table-column prop="balanceQuantity" label="剩余数量"></el-table-column>
              <el-table-column prop="useTime" label="使用时间"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination v-show="useTotal" :total="useTotal" :page-size="useLimit" @handleSizeChangeSub="pageChange($event,'use')" @handleCurrentChangeSub="handlePaging($event,'use')"/>
          </el-tab-pane>

          <el-tab-pane label="充值记录" name="recharge">
            <el-table :data="rechargeData" v-loading="tableLoad" border fit :stripe="true">
              <el-table-column type="index" label="序号" width="100"></el-table-column>
              <el-table-column prop="createTime" label="时间"></el-table-column>
              <el-table-column label="类型">
                <template slot-scope="scope">
                  {{scope.row.rechargeType == 'ADD' ? '增加' : '减少'}}
                </template>
              </el-table-column>
              <el-table-column prop="quantity" label="数量"></el-table-column>
              <el-table-column prop="balance" label="剩余数量"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination v-show="total" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-row>

     <!-- 默认签名弹窗 -->
    <el-dialog title="签名配置" :visible.sync="dialogsignVisible" width="500px">
      <div>
          <label>签名设置：</label>
          <el-input v-model="sign" style="width:200px"></el-input>
      </div>
      <div class="tips">
        <i class="el-icon-warning" style="margin-right:10px"></i>
        默认签名为：达易住修改该签名出现在用户短信开头【企业名称】
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogsignVisible = false">关闭</el-button>
        <el-button type="primary" @click="dialogsignVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <div class="custom-box" v-if="CustomVisible" @click.stop="">
      <img :src="qrCodeUrl" alt="">
    </div>
  </section>
</template>

<script>
  import { getSmsBalance } from '@/api/smtech/marketingTask'
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        crumbs: new Map([['营销云'], ['短信管理'], ['余额管理']]),
        tableLoad: false, // 数据加载
        smsBalance: 0, // 酒店剩余短信余额
        useData: [],
        total: 0,      // 充值记录列表总条目数
        page: 1,       // 充值记录当前页 默认第一页
        limit: 10,     // 充值记录每页显示数
        useTotal: 0,      // 使用明细列表总条目数
        usePage: 1,       // 使用明细当前页 默认第一页
        useLimit: 10,     // 使用明细每页显示数
        dialogsignVisible: false, // 签名弹窗
        rechargeData: [], // 充值记录
        sign: '达易住', // 签名
        qrCodeUrl: '', // 客服充值二维码
        CustomVisible: false, // 图片模态框
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getSmsBalance()
      this.getUseData();
      this.getRechargeData();
    },
    methods: {
      // 获取酒店剩余短信余额
      getSmsBalance() {
        getSmsBalance({ hotelId: this.hotelInfo.id }).then(({ success, data }) => {
          if (success) {
            this.smsBalance = data.smsBalance
          }
        })
      },

      // 获取使用明细数据
      getUseData() {
        this.tableLoad = true
        let param = {
          hotelId: this.hotelInfo.id,
          limit: this.useLimit,
          page: this.usePage,
        }
        
        this.$axios.get('/event/smsStatistics/getUsedSmsUsedDetail', param).then(res => {
          this.tableLoad = false
          this.useTotal = res.total
          this.useData = res.records
        })
      },

      // 获取充值记录数据
      getRechargeData() {
        this.tableLoad = true
        let param = {
          hotelId: this.hotelInfo.id,
          limit: this.limit,
          page: this.page
        }
        this.$axios.get('/hotel/hotelSmsRecharge/getPage', param).then(res => {
          this.tableLoad = false
          this.total = res.total
          this.rechargeData = res.records
        })
      },

      // 获取客服配置
      getCustomerConfig() {
        this.$axios.get('/hotel/hotelSmsBalance/getCustomerConfig', {}).then(res => {
          if (res.data) {
            this.qrCodeUrl = res.data.qrCodeUrl
            this.CustomVisible = true
          } else {
            this.$message.error('客服未配置')
          }
        })
      },

      // 改变每页数
      pageChange(num, type) {
        if (type == 'use') {
          this.useLimit = num
          this.getUseData()
        } else {
          this.limit = num;
          this.getRechargeData();
        } 
      },

      // 改变当前页
      handlePaging(num, type) {
        if (type == 'use') {
          this.usePage = num
          this.getUseData()
        } else {
          this.page = num;
          this.getRechargeData();
        }
      },
    },
    watch: {
      hotelInfo(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
          this.getSmsBalance()
          this.getUseData()
          this.getRechargeData()
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .cash {
    .box {
      display: flex;
      justify-content: flex-end;
      position: relative;
      .note-box {
        display: flex;
        position: absolute;
        height: 140px;
        line-height: 140px;
        top: -50px;
        left: 10px;
      }
    }
    .content-box {
      padding: 30px 50px;
    }
    .info {
      width: 576px;
      margin: -30px -20px 20px;
    }

    .custom-box {
      padding: 40px;
      background-color: #b3b3b3;
      position:fixed;
      top:calc(50% - 150px);
      left:calc(50% - 150px);
      z-index:999;
      img {
        width: 300px;
        height: 300px;
      }
    }
  }

  .tips {
    background:#f2f2f2;
    color:#a1a1a1;
    padding:5px 10px;
    margin: 10px 0;
  }
</style>
